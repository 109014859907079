<template>
    <div>
        <styled-interface
            hide-toolbar
            no-padding>
            <onboarding-header
                :dealer="currentDealer"
                step="Channel Setup">
                <template #actions>
                    <action-button
                        icon="indicator-error"
                        position="left"
                        light
                        normal-text
                        @click="saveAndExit()">
                        Save &amp; Exit
                    </action-button>
                </template>
            </onboarding-header>

            <dealer-product-update-dialog
                v-model="productDialogActive"
                :dealer="currentDealer"
                :product-ids="productIds"
                :redirect-url="redirectUrl"
                @complete="onProductUpdateComplete()" />

            <div class="pt-5">
                <div class="container fluid">
                    <div class="layout align-center justify-center row">
                        <loader v-if="loading" />

                        <styled-card
                            v-if="!loading"
                            hide-header
                            centered
                            simple
                            class="flex xs10 pa-5 my-5">
                            <div v-if="channel">
                                <div class="my-5">
                                    <h1>
                                        Channel Setup - {{ channel.name }}
                                    </h1>
                                </div>
                                <div class="mx-5">
                                    <div class="layout align-center row">
                                        <div class="ma-4">
                                            <img
                                                style="height: 50px;"
                                                :src="`/img/platforms/${channel.icon_name}.svg`">
                                        </div>
                                        <div class="ma-4">
                                            {{ channel.description }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="py-5">
                                <div class="light-line" />
                            </div>
                            <div>
                                <v-radio-group
                                    v-if="(
                                        (
                                            channelHasProduct(channel, PRODUCT_MARKETPLACE) ||
                                            channelHasProduct(channel, PRODUCT_MARKETPLACE_PLUS)
                                        ) && dealerInAutoVertical
                                    )"
                                    v-model="marketplaceProductSelection"
                                    class="product-radio-group"
                                    :disabled="marketplaceProductSelectionDisabled ? true : false"
                                    fill-height
                                    row>
                                    <div class="layout row align-center">
                                        <div class="flex xs3 text-center">
                                            <product-logo
                                                height="60px"
                                                :value="PRODUCT_MARKETPLACE" />
                                        </div>
                                        <div class="flex xs3">
                                            <p>
                                                {{ channel.details_page.marketplace_description }}
                                            </p>
                                        </div>
                                        <div class="flex xs6">
                                            <div class="layout row justify-space-around text-center">
                                                <div v-if="channelHasProduct(channel, PRODUCT_MARKETPLACE)">
                                                    <div class="layout align-center justify-center column">
                                                        <product-logo
                                                            height="55px"
                                                            :value="PRODUCT_MARKETPLACE" />
                                                        <v-radio
                                                            :value="getProductFromChannelAndCategory(channel, PRODUCT_MARKETPLACE)"
                                                            color="primary"
                                                            class="radio-bg-white pl-4 mt-3" />
                                                    </div>
                                                </div>
                                                <div v-if="channelHasProduct(channel, PRODUCT_MARKETPLACE_PLUS)">
                                                    <div class="layout align-center justify-center column">
                                                        <product-logo
                                                            height="55px"
                                                            :value="PRODUCT_MARKETPLACE_PLUS" />
                                                        <v-radio
                                                            :value="getProductFromChannelAndCategory(channel, PRODUCT_MARKETPLACE_PLUS)"
                                                            color="primary"
                                                            class="radio-bg-white pl-4 mt-3" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                v-if="channel.details_page.show_products_grid"
                                                class="layout row justify-space-around text-center">
                                                <div>
                                                    <a
                                                        href="https://buyerbridge.com/products/"
                                                        target="_blank">
                                                        See feature grid
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </v-radio-group>

                                <div
                                    v-if="(
                                        (
                                            channelHasProduct(channel, PRODUCT_MARKETPLACE) ||
                                            channelHasProduct(channel, PRODUCT_MARKETPLACE_PLUS)
                                        ) && dealerInAutoVertical
                                    )"
                                    class="py-5">
                                    <div class="light-line" />
                                </div>

                                <v-radio-group
                                    v-if="channelHasProduct(channel, PRODUCT_ADVERTISING) || channelHasProduct(channel, PRODUCT_ADVERTISING_PLUS)"
                                    v-model="adsProductSelection"
                                    class="product-radio-group"
                                    :disabled="adsProductSelectionDisabled ? true : false"
                                    fill-height>
                                    <div class="layout row align-center">
                                        <div class="flex xs3 text-center">
                                            <product-logo
                                                height="90px"
                                                :value="PRODUCT_ADVERTISING" />
                                        </div>
                                        <div class="flex xs9">
                                            <p>
                                                {{ channel.details_page.ads_description }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="layout row align-center my-4 products-wrapper">
                                        <div class="flex xs12 my-4 small-heading">
                                            Select Advertising Tier
                                        </div>
                                    </div>
                                    <div class="layout row align-center products-wrapper">
                                        <div class="flex xs6 ml-4">
                                            <div class="layout row justify-space-between text-center">
                                                <div v-if="channelHasProduct(channel, PRODUCT_ADVERTISING_LITE)">
                                                    <div class="layout align-center justify-center column">
                                                        <product-logo
                                                            height="55px"
                                                            :value="PRODUCT_ADVERTISING_LITE" />
                                                        <v-radio
                                                            :disabled="isProductAvailable(getProductFromChannelAndCategory(channel, PRODUCT_ADVERTISING_LITE))"
                                                            :value="getProductFromChannelAndCategory(channel, PRODUCT_ADVERTISING_LITE)"
                                                            color="primary"
                                                            class="radio-bg-white pl-4 mt-3" />
                                                    </div>
                                                </div>
                                                <div v-if="channelHasProduct(channel, PRODUCT_ADVERTISING)">
                                                    <div class="layout align-center justify-center column">
                                                        <product-logo
                                                            height="55px"
                                                            :value="PRODUCT_ADVERTISING" />
                                                        <v-radio
                                                            :disabled="isProductAvailable(getProductFromChannelAndCategory(channel, PRODUCT_ADVERTISING))"
                                                            :value="getProductFromChannelAndCategory(channel, PRODUCT_ADVERTISING)"
                                                            color="primary"
                                                            class="radio-bg-white pl-4 mt-3" />
                                                    </div>
                                                </div>
                                                <div v-if="channelHasProduct(channel, PRODUCT_ADVERTISING_PLUS)">
                                                    <styled-tooltip
                                                        :disabled="dealerInAutoVertical"
                                                        position="top">
                                                        <template #content>
                                                            <strong>Available only for automotive vertical.</strong>
                                                            Our AdvertisingPLUS package includes advanced features
                                                            (i.e., dynamic inventory ads), which are only available
                                                            for accounts within the automotive vertical.
                                                        </template>
                                                        <div
                                                            class="layout align-center justify-center column"
                                                            :class="{
                                                                'disabled-product': (
                                                                    isProductAvailable(getProductFromChannelAndCategory(channel, PRODUCT_ADVERTISING_PLUS)) ||
                                                                    !dealerInAutoVertical
                                                                )
                                                            }">
                                                            <product-logo
                                                                height="55px"
                                                                :value="PRODUCT_ADVERTISING_PLUS" />
                                                            <v-radio
                                                                :disabled="(
                                                                    isProductAvailable(getProductFromChannelAndCategory(channel, PRODUCT_ADVERTISING_PLUS)) ||
                                                                    !dealerInAutoVertical
                                                                )"
                                                                :value="getProductFromChannelAndCategory(channel, PRODUCT_ADVERTISING_PLUS)"
                                                                color="primary"
                                                                class="radio-bg-white pl-4 mt-3" />
                                                        </div>
                                                    </styled-tooltip>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="layout row align-center">
                                        <div class="flex xs8">
                                            <div>
                                                <div
                                                    v-if="channel.details_page.show_products_grid"
                                                    class="layout row justify-space-around text-center">
                                                    <div>
                                                        <a
                                                            href="https://buyerbridge.com/products/"
                                                            target="_blank">
                                                            See feature grid
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </v-radio-group>
                            </div>

                            <div
                                v-if="channelHasProduct(channel, PRODUCT_ADVERTISING) || channelHasProduct(channel, PRODUCT_ADVERTISING_PLUS)"
                                class="py-5">
                                <div class="light-line" />
                            </div>

                            <div class="text-center ma-5">
                                <styled-button
                                    :disabled="!productHasBeenSelected"
                                    @click="productDialogActive = true">
                                    CONTINUE
                                </styled-button>
                            </div>
                        </styled-card>
                    </div>
                </div>
            </div>
        </styled-interface>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Loader from '@/components/globals/Loader';
import StyledInterface from '@/components/globals/StyledInterface';
import StyledCard from '@/components/globals/StyledCard';
import StyledButton from '@/components/globals/StyledButton';
import StyledTooltip from '@/components/globals/StyledTooltip';
import OnboardingHeader from '@/components/globals/OnboardingHeader';
import ActionButton from '@/components/globals/ActionButton';
import ProductLogo from '@/components/globals/ProductLogo';
import DealerProductUpdateDialog from '@/components/globals/DealerProductUpdateDialog';
import {
    PRODUCTS,
    PRODUCT_MARKETPLACE,
    PRODUCT_MARKETPLACE_PLUS,
    PRODUCT_ADVERTISING_LITE,
    PRODUCT_ADVERTISING,
    PRODUCT_ADVERTISING_PLUS,
    PRODUCT_GROUP_MARKETPLACE,
    PRODUCT_GROUP_ADVERTISING,
    PRODUCT_MICROSOFT_MARKETPLACE_CPC,
    PLATFORM_FACEBOOK,
    PLATFORM_SNAPCHAT,
    PLATFORM_TIKTOK,
    PLATFORM_PINTEREST
} from '@/helpers/globals.js';
import {
    getDealerChannelByPlatformName,
    getProductFromChannelAndCategory,
    channelHasProduct,
    hasLargestProductForChannel,
    getProductsForChannelAndProductGroup
} from '@/components/onboarding/channel-setup/channels.js';
import { getChannelProductUpgrades } from '@/components/onboarding/channel-setup/channels';
import { getProductCategoryByBBProductId } from '@/components/onboarding/channel-setup/channels.js';

export default {
    name: 'ProductSelection',
    title: 'Product Selection',
    components: {
        Loader,
        StyledInterface,
        StyledCard,
        StyledButton,
        OnboardingHeader,
        ActionButton,
        ProductLogo,
        DealerProductUpdateDialog,
        StyledTooltip
    },
    data() {
        return {
            loading: false,
            marketplaceProductSelection: null,
            marketplaceProductSelectionDisabled: false,
            adsProductSelection: null,
            adsProductSelectionDisabled: false,
            PRODUCT_MARKETPLACE,
            PRODUCT_MARKETPLACE_PLUS,
            PRODUCT_ADVERTISING_LITE,
            PRODUCT_ADVERTISING,
            PRODUCT_ADVERTISING_PLUS,
            PRODUCT_MICROSOFT_MARKETPLACE_CPC,
            PRODUCTS,
            selectedAddOns: [],
            channel: {},
            productDialogActive: false
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
            currentAgency: (state) => state.agency.currentAgency,
        }),
        ...mapGetters([
            'dealerInAutoVertical'
        ]),
        redirectUrl() {
            return this.$route.query?.redirect_url ?? null;
        },
        productsAvailableForUpgrade() {
            const enrolledProductIds = this.dealerProducts.map(product => product.id);
            return getChannelProductUpgrades(this.channel.platform_id, enrolledProductIds);
        },
        productHasBeenSelected() {
            return Boolean(this.productIds.length);
        },
        ...mapGetters([
            'userIsAdmin',
            'activeDealerOnboardings',
            'dealerProducts',
            'dealerProductIds',
        ]),
        productIds() {
            let filteredMpProductId = null;
            if (this.marketplaceProductSelection) {
                this.dealerProductIds.includes(this.marketplaceProductSelection) ? filteredMpProductId = null : filteredMpProductId = this.marketplaceProductSelection;
            }

            let filteredAdsProductId = null;
            if (this.adsProductSelection) {
                this.dealerProductIds.includes(this.adsProductSelection) ? filteredAdsProductId = null : filteredAdsProductId = this.adsProductSelection;
            }

            return [filteredMpProductId, filteredAdsProductId, ...this.selectedAddOnsIds].filter(x => x);
        },
        addOnProducts() {
            const addOns = this.PRODUCTS.filter(product => {
                return product.addOns.length > 0;
            }).map(product => {
                return product.addOns;
            }).flat();
            // removing any duplications
            const uniqueAddOns = addOns.filter(function(a, i, s) {
                return s.indexOf(a) == i;
            });
            return uniqueAddOns;
        },
        selectedAddOnsIds() {
            return this.selectedAddOns.map(addOn => {
                return addOn.id;
            });
        }
    },
    watch: {
        adsProductSelection() {
            this.selectedAddOns = [];
        }
    },
    async created() {
        this.channel = getDealerChannelByPlatformName(this.currentDealer, this.$route.params.channel) ?? null;

        if (this.channel.key === PLATFORM_FACEBOOK) {
            this.checkFacebookAuthStatus();
        }

        // Redirect the use to channel selection screen if agency not authenticated
        let allowedOmniAuthenticationChannels = [PLATFORM_SNAPCHAT, PLATFORM_TIKTOK, PLATFORM_PINTEREST];
        if (allowedOmniAuthenticationChannels.includes(this.channel.name.toLowerCase())) {
            await this.checkPlatformAuthStatus();
        }

        //Make sure the marketplace product is being used by the channel before populating the current dealer product id
        if (channelHasProduct(this.channel, PRODUCT_MARKETPLACE) || channelHasProduct(this.channel, PRODUCT_MARKETPLACE_PLUS)) {
            //Pre populate the current dealer product id
            const selectedMPProduct = getProductsForChannelAndProductGroup(this.channel, PRODUCT_GROUP_MARKETPLACE).find(product => {
                return this.dealerProductIds.includes(product.bb_product_id);
            });

            if (selectedMPProduct?.bb_product_id) {
                this.marketplaceProductSelection = selectedMPProduct.bb_product_id;
            }

            //TODO come back to this and test it out
            if (this.channel.products.length > 1) {
                this.marketplaceProductSelectionDisabled = hasLargestProductForChannel(this.channel, PRODUCT_GROUP_MARKETPLACE, this.dealerProductIds);
            } else {
                this.marketplaceProductSelectionDisabled = false;
            }
        }

        //Make sure the ads product is being used by the channel before populating the current dealer product id
        if (channelHasProduct(this.channel, PRODUCT_ADVERTISING) || channelHasProduct(this.channel, PRODUCT_ADVERTISING_PLUS)) {
            //Pre populate the current dealer product id
            const selectedAdsProduct = getProductsForChannelAndProductGroup(this.channel, PRODUCT_GROUP_ADVERTISING).find(product => {
                return this.dealerProductIds.includes(product.bb_product_id);
            });

            if (selectedAdsProduct?.bb_product_id) {
                this.adsProductSelection = selectedAdsProduct.bb_product_id;
            }

            //TODO come back to this and test it out
            if (this.channel.products.length > 1) {
                this.adsProductSelectionDisabled = hasLargestProductForChannel(this.channel, PRODUCT_GROUP_ADVERTISING, this.dealerProductIds);
            } else {
                this.adsProductSelectionDisabled = false;
            }
        }
    },
    methods: {
        isDisabled(id) {
            const selectedProduct = this.PRODUCTS.find(product => product.id === this.adsProductSelection);
            if (selectedProduct) {
                const availableAddOn = selectedProduct.addOns.find(addOn => addOn.id === id);
                if (availableAddOn) return false;
            }
            return true;
        },
        isProductAvailable(id) {
            if (this.productsAvailableForUpgrade.includes(id)) {
                return false;
            } else {
                return true;
            }
        },
        scrollTo(refName) {
            this.$refs[refName].scrollIntoView({ behavior: 'smooth' });
        },
        ...mapActions([
            'waitForDealerLoaded',
            'updateCurrentDealer'
        ]),
        /**
         * Check if platform is authenticated or not
         */
        async checkPlatformAuthStatus() {
            this.loading = true;

            try {
                await this.getPlatformAuthStatus(this.channel.name.toLowerCase()).then(response => {
                    if (!response.data.token) {
                        // To make sure that component is fully removed
                        // otherwise it will be stuck in loading state
                        this.$destroy();

                        this.$router.push({ name: 'channel-setup' });
                    }
                });
            } catch (error) {
                this.$destroy();

                this.$router.push({ name: 'channel-setup' });
            }

            this.loading = false;
        },
        getPlatformAuthStatus(platformName) {
            return this.$apiRepository.getPlatformAuthStatus(this.currentAgency.id, platformName);
        },
        async init() {
            this.loading = true;

            // Ensure the dealer is loaded so we can read products
            await this.waitForDealerLoaded();

            // Don't allow access to this screen if there's already products
            if (this.dealerProducts.length) {
                this.$router.push({
                    name: 'dealer',
                    params: {
                        dealer_id: this.currentDealerId
                    },
                    query: {
                        product_selection_reroute: true
                    }
                });
                return;
            }

            this.loading = false;
        },
        onProductUpdateComplete() {
            this.marketplaceProductSelection = null;
            this.adsProductSelection = null;
        },
        async saveAndExit() {
            await this.updateCurrentDealer();
            this.$destroy();
            this.$router.push({ name: 'facebook-dashboard' });
        },
        checkFacebookAuthStatus() {
            // Check if the agency does not have the facebook_business_id set
            if (this.currentAgency?.facebook_business_id === null) {
                this.$destroy();
                this.$router.push({ name: 'channel-setup' });
            }
        },
        clickAddOn(addOn) {
            // Check if the add-on is already selected
            if (this.selectedAddOnsIds.includes(addOn.id)) {
                // Remove the add-on from the selected add-ons
                this.selectedAddOns = this.selectedAddOns.filter(selectedAddOn => {
                    return selectedAddOn.id !== addOn.id;
                });

            } else {
                // Add the add-on to the selected add-ons
                this.selectedAddOns.push(addOn);
            }
        },
        channelHasProduct,
        getProductFromChannelAndCategory,
        hasLargestProductForChannel,
        getProductCategoryByBBProductId,
        getProductsForChannelAndProductGroup
    }
};
</script>

<style lang="scss" scoped>
.light-line {
    height: 1px;
    background-color: #A1A6A8;
}

.add-on-selection {
    cursor: pointer;
    max-width: 150px;
    width: 150px;
    text-align: center;
    background-color: #FAFBFC;
    padding-bottom: 25px;
    position: relative;
}

.add-on-disabled {
    opacity: 0.4;
    pointer-events: none;
}

.add-on-question {
    position: absolute;
    top: 0px;
    right: 0px;
}

.dashed-border {
    padding: 15px;
    border-radius: 8px;
    opacity: 1;
    margin-bottom: 4px;
    margin-top: 34px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%2303A2EAFF' stroke-width='3' stroke-dasharray='5%2c 7' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
}

.selected-border {
    padding: 15px;
    border-radius: 0 0 8px 8px;
    border: 5px solid rgba(0, 162, 234, 1);
    opacity: 1;
    background-color: rgba(250, 251, 252, 1);
    width: 154px;
    max-width: 154px;
}

.requirement {
    color: $blue-bg;
    font-size: 12px;
    text-align: center;
    margin-top: 3px;
    position: absolute;
    top: calc(100% + 3px);
    left: 15px;
    right: 15px;
    line-height: 14px;
}

.add-on-logo {
    max-height: 25px;
    margin: 0 auto;
    display: block;
}

.add-on-grid {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
}

.add-on-button {
    background-color: rgb(126, 211, 33);
    color: white;
    padding: 1px 10px;
    border-radius: 5px;
    margin-top: 30px;
    margin-bottom: 10px;
    font-weight: 600;
}

.remove-add-on-button {
    background-color: rgb(255, 61, 0);
    color: white;
    padding: 1px 10px;
    border-radius: 5px;
    margin-top: 30px;
    margin-bottom: 10px;
    font-weight: 600;
}

.selected-top {
    background-color: rgba(0, 162, 234, 1);
    color: white;
    padding: 5px 10px 1px 10px;
    border-radius: 8px 8px 0 0;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

.add-on-product-name {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    text-transform: uppercase;
}

.small-heading {
    font-size: 13px;
    font-weight: 400;
    text-align: left;
}

.products-wrapper {
    margin-left: 7%;
}
</style>
